.ehlykj {
  margin-left: 0 !important;
  max-width: 100% !important;
}

.img-token >div {
    position: relative;
}

.img-Token {
  width: calc(100%);
 margin-left: 10px;
 margin-top: 10px;
  max-width: 30px;
  
}
 .img-quoteToken {
  width: calc(95%);
    position: absolute;
    max-width: 20px;
    z-index: 0;
  }

.farm-card {
  display: flex;
  flex-direction: column;
  border: solid 1px;
  border-radius: 10px;
  padding: 10px;
  width: 20%;
  box-sizing: border-box;
  justify-content: center;
}


.linear-card{
 margin:30px 20px 50px 20px;
content: "";
  background: gray;

  height: 2px;

 
}
.size-farmsup{
  max-width: 150px;
}

.ficUmB{
  margin-left: 0 !important;
  max-width: 100% !important;
}

.cursor-point{
  cursor: pointer;
}
nav{
  color: white;
}

.bg{
  background-color: #0D0041;

}


h1,h2,i,h4{
  color: white !important;
 
}

.farm-detail>h4{

  font-size: calc(2vw);
  
}



h3,h5{
  font-size: 12px !important;
  color: rgba(255, 253, 253, 0.651)
}

.farm-detail > h3 {
 color: white;
  font-size: 1.5vw !important;
}


.btn-1{
  color: white !important;
background-color:#FF5500 !important;
}

.bg-box{
background-color: #190F56;
}

.popup .overlay {
  position:fixed;
  top:0px;
  left:0px;
  width:100vw;
  height:100vh;
  background:rgba(0,0,0,0.7);
  z-index:1;
  display:none;
}
 
.popup .content {
  position:absolute;
  top:80%;
  left:50%;
  transform:translate(-50%,-50%) scale(0);
  background:#190F56;
  width:95%;
  max-width:100%;
  height:360px;
  z-index:2;
  text-align:center;
  padding:20px 15px 20px 41px;
  box-sizing:border-box;
  font-family:"Open Sans",sans-serif;
}
 
.popup .close-btn {
  cursor:pointer;
  position:absolute;
  right:20px;
  top:20px;
  width:30px;
  height:30px;
  background:#222;
  color:#fff;
  font-size:25px;
  font-weight:600;
  line-height:30px;
  text-align:center;
  border-radius:50%;
}
 
.popup.active .overlay {
  display:block;
}
 
.popup.active .content {
  transition:all 300ms ease-in-out;
  transform:translate(-50%,-50%) scale(1);
}


#outer {
  overflow: hidden;
}

#outer div {
  display: inline-block;
}


#loop {
  white-space: nowrap;
  animation: loop-anim 35s linear infinite;
}

/* @keyframes loop-anim {
  0% {
      margin-left: 100%;
  }
  100% {
      margin-left: -100% 
  }
} */

@keyframes loop-anim {
  0% {
    transform: translate(50%, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.copy:hover{
  color:#37ff00 !important
} 

.acc:hover{
  color:#37ff00 !important
}
.btn-1:hover{
background-color: #b83f03 !important;
}

#add-alert{
  border-radius: 20px;
  padding: 10px 0;
  background-color: rgb(255, 38, 0);
  color:white;
  text-align: center;
  width: 300px;
  position: absolute;
  opacity: 0;
  left: -200px;
 transition: opacity 0.5s linear;
}

#copy-alert1{
  border-radius: 10px;
  color: black;
  position: absolute;
  top:0%;
  right:0%;
  background-color: #37ff00;
  padding: 5px 5px;
 opacity: 0;
 transition: opacity 0.5s linear;
}
#copy-alert2{
  border-radius: 10px;
  color: black;
  position: absolute;
  top:0%;
  right:0%;
  background-color: #37ff00;
  padding: 5px 5px;
 opacity: 0;
 transition: opacity 0.5s linear;
}
#copy-alert3{
  border-radius: 10px;
  color: black;
  position: absolute;
  top:0%;
  right:0%;
  background-color: #37ff00;
  padding: 5px 5px;
 opacity: 0;
 transition: opacity 0.5s linear;
}